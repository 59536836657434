'use client';

import Image from 'next/image';

import errorImage from '@/public/images/error.png';

export default function Error(): JSX.Element {
  return (
    <div className='flex flex-col items-center justify-center w-full h-screen lg:justify-start font-arizona-sans bg-palestLavender'>
      <div className='flex flex-col w-1/2 text-center lg:mt-12'>
        <p className='mt-4 text-xl font-medium text-darkNavy lg:text-[24px]'>
          Oops! Something went wrong. Please try again later.
        </p>
      </div>
      <div className='flex w-1/2 my-8 lg:w-1/6'>
        <Image priority alt='error' src={errorImage} />
      </div>
    </div>
  );
}
